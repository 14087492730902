<template>
  <b-row class="px-2">
    <b-col cols="12">
      <h4 class="primary fw-bold">{{ title }}</h4>
    </b-col>
    <b-col cols="12" class="py-3">
      <b-form-row>
        <b-col cols="12" md="6">
          <b-form-group label="Tipo de identificación">
            <b-form-select
              :options="$store.getters.listaTiposIdentificacion"
              v-model="$v.formR.tipoIdentificacion.$model"
              :state="$v.formR.tipoIdentificacion.$dirty ? !$v.formR.tipoIdentificacion.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formR.tipoIdentificacion) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="textoIdentificacion">
            <b-form-input
              v-model="$v.formR.cedula.$model"
              :state="$v.formR.cedula.$dirty ? !$v.formR.cedula.$error : null"
            />
            <b-form-invalid-feedback>{{ validarCampo($v.formR.cedula) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col cols="12" class="pb-4">
      <b-row>
        <b-col cols="12" md="6" offset-md="6" class="pb-3">
          <b-form-checkbox class="primary" v-model="readTerminos">
            Acepto términos y condiciones
            <a class="danger" target="_blank" href="static/Terminos_y_condiciones.pdf">Ver términos</a>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" md="6" offset-md="6">
          <b-form-checkbox class="primary" v-model="readPoliticas">
            Acepto política de tratamiento de datos
            <a class="danger" target="_blank" href="static/Tratamiento_de_datos.pdf">Ver políticas</a>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" class="d-flex justify-content-between">
      <b-button variant="danger" @click="retornarPagina" class="mr-3"> <i class="las la-reply" /> Regresar </b-button>
      <b-button variant="danger" :disabled="!validarForm" @click="validarUsuario">
        <i class="las la-check-circle" /> Aceptar
      </b-button>
    </b-col>
    <template v-if="esPagoWeb">
      <ModalValidarOtp
        :tiempoReenvioCodigo="tiempoHabilitarReenvio"
        :digitosCelular="digitosCelular"
        @reenviarCodigo="reenviarCodigo"
        @sendForm="validarCodigo"
        @useModal="useModalOtp"
        :modal="modalOtp"
        ref="modalOtp"
      />
    </template>
  </b-row>
</template>

<script>
import PublicService from "@/app/core/api/public";
import CarteraService from "@/app/core/api/cartera";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, numeric, maxLength } from "vuelidate/lib/validators";
import ValidacionIdentidadService from "@/app/core/api/validacion-identidad";

// COMPONENTS
import ModalValidarOtp from "./ModalValidarOtp.vue";

export default {
  mixins: [mixinsEvents],
  components: { ModalValidarOtp },
  props: {
    esPagoWeb: { type: Boolean, default: false },
    esLevPrendaWeb: { type: Boolean, default: false },
    title: { type: String, default: "Ingresa tu identificación" },
  },
  data() {
    return {
      readTerminos: false,
      readPoliticas: false,
      digitosCelular: null,
      modalOtp: { isOpen: false },
      tiempoHabilitarReenvio: null,
      formR: { tipoIdentificacion: null, cedula: null },
    };
  },
  computed: {
    validarForm() {
      return !this.$v.formR.$invalid && this.readTerminos && this.readPoliticas;
    },
    textoIdentificacion() {
      return this.esPagoWeb || this.esLevPrendaWeb ? "Identificación titular crédito" : "Número de identificación";
    },
  },
  validations: {
    formR: { cedula: { required, numeric, maxLength: maxLength(12) }, tipoIdentificacion: { required } },
  },
  methods: {
    validarUsuario() {
      this.$v.formR.$touch();
      if (this.$v.formR.$invalid) return;
      if (!this.readTerminos) return AlertsService.error("Registro", "Debe aceptar los terminos y condiciones.");
      if (!this.readPoliticas)
        return AlertsService.error("Registro", "Debe aceptar la politica de tratamiento de datos.");

      this.$store.commit("setUserData", { identificacion: this.formR.cedula });

      if (!this.esPagoWeb && !this.esLevPrendaWeb) {
        PublicService.validarUsuario(this.formR.cedula)
          .then((response) => {
            const data = response.data;
            if (data.estado === 0) this.getDatosCliente();
            else {
              let msg = "El usuario ya se encuentra registrado en nuestra Sucursal Virtual ProgreSER.";
              if (data.estado === 5) {
                msg = `${msg} Solo debe activar su cuenta en el link enviado al correo electrónico que diligenciaste al momento del registro.`;
              }

              AlertsService.error("Registro", msg);
            }
          })
          .catch((error) => {
            if (error.response) this.getDatosCliente();
            else AlertsService.error("Error", "Error de red");
          });
      } else this.getDatosCliente();
    },
    getDatosCliente() {
      const body = { tipoDocumento: this.formR.tipoIdentificacion, numeroDocumento: this.formR.cedula };
      ValidacionIdentidadService.getDatosCliente(body).then((response) => {
        const data = response.data;
        if (this.esPagoWeb || this.esLevPrendaWeb) {
          const dataCliente = {
            direccion: data.direccion,
            numeroCelular: data.celular,
            correoElectronico: data.correoElectronico,
            tipoDocumento: this.formR.tipoIdentificacion,
            identificacion: data.identificacion.toString(),
            nombreCliente: `${data.primerNombre} ${data.segundoNombre} ${data.primerApellido} ${data.segundoApellido}`,
          };
          this.$store.commit("setUserData", dataCliente);
          this.info = { ...dataCliente };

          if (this.esPagoWeb) this.getCreditos();
          if (this.esLevPrendaWeb) this.getCreditosLevPrenda();
        } else {
          const body = {
            origenDatos: data.origenDatos,
            primerNombre: data.primerNombre,
            segundoNombre: data.segundoNombre,
            primerApellido: data.primerApellido,
            segundoApellido: data.segundoApellido,
          };

          this.$store.commit("setDataShared", { ...this.formR, ...body });

          this.$emit("nextStep");
        }
      });
    },
    getCreditos() {
      const { tipoDocumento, identificacion } = this.$store.getters;
      const body = { tipoDocumento: tipoDocumento, numeroDocumento: Number(identificacion), pantalla: "Pagos" };

      CarteraService.getCreditos(body).then((response) => {
        if (response.data.estado) {
          const creditosActivos = [];

          response.data.listaCreditos
            .filter((x) => x.estado !== "CANCELADO")
            .map((item) => {
              if (
                item.edadCartera === 20 ||
                item.diasEnMora > 0 ||
                item.estado === "Activo" ||
                item.estado === "VIGENTE" ||
                item.estado === "MORA"
              ) {
                creditosActivos.push({ ...item, estado: "ACTIVO" });
              }
            });
          if (creditosActivos.length > 0) {
            this.$store.commit("setCreditosActivos", creditosActivos);
            if (response.data.validarOtpPagos) {
              this.generarCodigo();
            } else {
              this.$router.push("/pagar-cuota-web");
            }
          } else {
            AlertsService.error("Consulta de Creditos", "No hay créditos activos para el número de identificación.");
          }
        } else {
          AlertsService.error("Consulta de Creditos", response.data.mensaje);
        }
      });
    },
    getCreditosLevPrenda() {
      const body = { tipoDoc: this.$store.getters.tipoDocumento, cedula: this.$store.getters.identificacion };

      CarteraService.getCreditosLevPrenda(body).then((response) => {
        const data = response.data.listaCreditos;
        if (data.length > 0) {
          this.$store.commit("setCreditosLevPrenda", data);
          this.$store.commit("setIsGetCreditosLevPrenda", true);
          this.$router.push("/levantamiento-prenda-web");
        } else {
          AlertsService.error("Levantamiento de Prenda", "Actualmente no tienes créditos cancelados.");
        }
      });
    },
    retornarPagina() {
      if (this.esPagoWeb || this.esLevPrendaWeb) location.href = "https://progreser.com/";
      else this.$router.push("/login");
    },
    useModalOtp() {
      this.modalOtp.isOpen = !this.modalOtp.isOpen;
    },
    generarCodigo() {
      const body = { numeroDocumento: this.info.identificacion, idSolicitud: 0, estado: 0, tipo: "1" };

      ValidacionIdentidadService.generarCodigoValidacionPago(body).then((response) => {
        const data = response.data.extras;
        this.digitosCelular = data.celular ? data.celular.slice(-4) : "";
        this.tiempoHabilitarReenvio = data.tiempoHabilitarReenvio;
        this.modalOtp.isOpen = true;
        setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
      });
    },
    validarCodigo(codigo) {
      const body = { cedula: this.info.identificacion, codigoOTP: codigo, url: "" };

      ValidacionIdentidadService.validarCodigoValicacionPago(body).then((response) => {
        AlertsService[response.data.estado ? "success" : "error"]("Validación Código OTP", response.data.mensaje);
        if (response.data.estado) {
          this.modalOtp.isOpen = false;
          this.$router.push("/pagar-cuota-web");
        }
        if (response.data.cerrarModal) this.modalOtp.isOpen = false;
      });
    },
    reenviarCodigo() {
      const body = { numeroDocumento: this.info.identificacion, idSolicitud: 0, estado: 0, tipo: "2" };

      ValidacionIdentidadService.reenviarCodigoValidacionPago(body).then((response) => {
        const data = response.data;
        AlertsService[response.data.estado ? "success" : "error"]("Validación Código OTP", response.data.mensaje);
        this.tiempoHabilitarReenvio = data.tiempoHabilitarReenvio;
        setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
      });
    },
  },
};
</script>
